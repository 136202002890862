<template>
  <div>
    <HeaderComponent />

    <router-view />
    
    <FooterComponent />

    <WhatsappBtn />
  </div>
</template>

<script>
export default {
  name: "base-layout",
  components: {
    HeaderComponent: () => import("./HeaderComponent"),
    FooterComponent: () => import("./FooterComponent"),
    WhatsappBtn: () => import("./WhatsappBtn"),
  },
  data: () => ({
    maps_embed: null
  }),
  methods: {
    getSystemClient() {
      this.$api
        .get("systemclients")
        .then((res) => {
          console.log(res);
          this.maps_embed = res.data.data.maps_embed;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    this.getSystemClient();
  },
};
</script>