import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import obj from './provider/api'
import VueCookies from 'vue-cookies'
// import VueTheMask from 'vue-the-mask'

Vue.config.productionTip = false
Vue.prototype.$api = obj.api
Vue.use(VueCookies)
// Vue.use(VueTheMask)

let colors = {}

obj.api.get('systemclients', {
    headers: {
        "Authorization": `Bearer ${obj.token}`,
    },
})
    .then((res) => {
        console.log(res.data.data);

        

        VueCookies.set("title", res.data.data.title);
        document.title = res.data.data.title;

        VueCookies.set("subtitle", res.data.data.subtitle);
        VueCookies.set("description", res.data.data.description);

        document.querySelector('head meta[name="description"]').setAttribute('content', res.data.data.description)
        
        var keywords = ""
        res.data.data.keywords.forEach(keyword => {
          keywords += keyword + ", "
        });

        document.querySelector('head meta[name="keywords"]').setAttribute('content', keywords)

        VueCookies.set("contact", res.data.data.contact);
        VueCookies.set("is_whatsapp", res.data.data.is_whatsapp);
        VueCookies.set("contact_email", res.data.data.contact_email);

        VueCookies.set("cpf_cnpj", res.data.data.cpf_cnpj);
        
        VueCookies.set("site_logo", res.data.data.logo);
        VueCookies.set("site_logo_hz", res.data.data.logo_horizontal);
        VueCookies.set("site_logo_white", res.data.data.logo_white);
        VueCookies.set("site_logo_black", res.data.data.logo_black);
        colors = res.data.data.colors

        for (let i = 0; i < colors.length; i++) {    
          vuetify.framework.theme.themes.light[colors[i].label] = colors[i].value
        }

        vuetify.framework.theme.themes.light.error = "#FF5252"
        vuetify.framework.theme.themes.light.warning = "#FFC107"
        vuetify.framework.theme.themes.light.success = "#4CAF50"

    })
    .catch(() => {
        console.error("Erro ao carregar dados do cliente");
    })


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
