import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseLayout from '@/components/BaseLayout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    component: BaseLayout,
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/HomeView')
      },
      {
        path: 'property/:id',
        name: 'property-view',
        component: () => import('@/views/PropertyView')
      },
      {
        path: 'properties/:type',
        name: 'properties-list',
        component: () => import('@/views/PropertiesListView')
      },
      {
        path: 'properties/filter',
        name: 'properties-filter',
        component: () => import('@/views/PropertiesListView')
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('@/views/AboutUs')
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
