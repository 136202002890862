import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    framework: {
        theme: {
            themes: {
                light: null,
                dark: null
            },
        },
    },
});

export default vuetify
