import Vue from 'vue'
import VueCookies from 'vue-cookies'
import axios from 'axios'

Vue.use(VueCookies)

/*
** Criando a instância do axios
*/
const api = axios.create({
    // baseURL: "http://localhost:8000"
    baseURL: "https://ehomeapi.encode.dev.br"
})

const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7InR5cGUiOiJ1c2VyIiwiaXNzIjoiZWhvbWVlbmNvZGUiLCJzeXN0ZW1fY2xpZW50X2lkIjoxLCJuYW1lIjoiQWRtaW5pc3RyYWRvciIsImVtYWlsIjoiYWRtaW5AY29ycmV0b3JlbWVyc29uLmNvbS5iciJ9fQ==.DIJPy0kBngd_chHxXoAysE6ewm1JnbLcVN6YTCKfyHc"

/*
** Definindo um token de segurança para requisições
** feitas por usuários logados para endpoints que esperam um token
*/
api.interceptors.request.use(async config => {
    api.defaults.headers.authorization = `Bearer ${token}`
    return config
})

export default { api, token }