import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    filters: {
      category: null,
      type: null,
      term: ""
    }
  },
  getters: {
    getFilters: state => state.filters
  },
  mutations: {
    PROPERTIES_FILTER(state, filters) {
      state.filters = filters
    }
  },
  actions: {
    propertiesFilter({commit}, payload) {
      commit('PROPERTIES_FILTER', payload);
    }
  },
  modules: {
  }
})
